section.projekte {
  padding: 0 0 $space-sm;
  text-align: center;
  @include media($tablet) {
    padding: 0 0 $space-md; } }

#projekte {
  list-style: none;
  margin: 0;
  padding: $space-sm 0 0;
  li {
    @include span-columns(6);
    @include omega(2n);
    margin-bottom: rem(16);
    @include media($desktop) {
      @include omega-reset(2n);
      @include span-columns(4);
      @include omega(3n); } }
  a {
    display: block;
    margin: 0 0 rem(5);
    @include media($tablet) {
      margin: 0 0 rem(10); } }
  h3 {
    margin: 0;
    text-align: left;
    line-height: 1.5;
    &:after {
      display: none; }
    @include font-stack-bold();
    font-size: rem(12);
    text-transform: none;
    @include media($tablet) {
      font-size: rem(18);
      text-transform: uppercase; } }
  h4 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    text-transform: none;
    text-align: left;
    font-size: rem(10);
    text-transform: uppercase;
    color: $grey; } }

body.home #projekte li {
  display: none;
  video {
    max-width: 100%;
    height: auto; } }

button#mehrLaden {
  border: 2px solid $orange;
  @include font-stack;
  font-size: $font-size-sm;
  background: white;
  display: inline-block;
  min-width: 80px;
  padding: 12px 20px 10px;
  outline: 0;
  margin-top: $space-sm;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: $orange;
  cursor: pointer;
  cursor: hand;
  &:hover,
  &:focus {
    background: $orange;
    color: white; } }
