strong,
em {
  @include font-stack-bold; }

a {
  color: $black;
  text-decoration: none;
  border-bottom: 1px solid $black; }


.orange_bg {
  a {
    color: white;
    border-bottom: 1px solid white; }
  .imgLink {
    border-bottom: none; } }

.imgLink {
  border-bottom: none; }


h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
  text-align: center;
  text-transform: uppercase; }

h2 {
  @include media($tablet) {
    letter-spacing: 2px; } }

h1 {
  @include font-stack-bold();
  font-size: rem(20);
  margin: rem(20) rem(20) rem(10);
  @include media($tablet) {
    font-size: rem(36);
    margin: $space-md rem(20) rem(20); } }

h2 {
  @include font-stack-bold();
  color: $orange;
  font-size: rem(14);
  @include media($tablet) {
    font-size: rem(18);
    margin: 0 0 $space-sm; } }

h3 {
  position: relative;
  margin: 0 0 $space-md;
  font-size: rem(24);
  @include media($tablet) {
    font-size: rem(36);
    &:after {
      width: 150px;
      margin-left: -75px; } } }

section h3 {
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -10px;
    height: 1px;
    margin-left: -50px;
    width: 100px;
    background: $black;
    @include media($tablet) {
      font-size: rem(36);
      &:after {
        width: 150px;
        margin-left: -75px; } } } }

.orange_bg h3 {
  &:after {
    background: white; } }


.underline {
  @include font-stack;
  font-size: rem(18);
  border-bottom: 1px solid $lightgrey;
  margin-bottom: rem(9);
  color: $black;
  text-align: left;
  @include media($tablet) {
    margin-bottom: rem(18); }
  span {
    border-bottom: 1px solid $black;
    display: inline-block;
    margin: 0 0 -1px 0;
    padding: 0 rem(5) 0 0; } }

h4 {
  font-size: $font-size-sm;
  margin: 0;
  @include media($tablet) {
    font-size: $font-size-md;
    text-align: left; } }

p,
address {
  margin: 0 0 $font-size-sm;
  line-height: 1.5;
  font-style: normal; }
.einleitung {
  text-align: center;
  padding: 0 rem(20) rem(20);
  line-height: 1;
  @include media($tablet) {
    font-size: rem(24);
    padding: 0 7% $space-sm; }
  @include media($desktop) {
    padding: 0 14% $space-sm; } }
.justify {
  hyphens: auto;
  text-align: justify; }


ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block; }
