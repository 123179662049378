#branchenerfahrung {
  .doughnutWrap {
    @include row;
    @include media($desktop) {
      width: 85%; }
    .doughnut {
      //@include span-columns(8)
      position: relative;
      text-align: right;
      height: 20em; } }
  ul.kunden {
    width: 100%;
    margin: $space-sm auto 0;
    text-align: center;
    @include media($tablet) {
      width: 90%;
      margin: $space-md auto 0; }
    li {
      display: inline-block;
      width: 30%;
      @include media($desktop) {
        width: 19%; } } } }
