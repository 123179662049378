@charset "UTF-8";
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

/* Author : Felix Zemmel <info@orangeadvertising.de> */
/* Date : 2015.08.15 */
/* File : css/style.css */
/* Made with Bourbon 4.2.4 + Neat 1.7.2 */
/* http://bourbon.io/ */
/* VARIABLES */
/* MIXINS */
/* GRID */
/* GRID */
.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0.625rem; }
  .container::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (min-width: 641px) {
    .container {
      padding: 0 30px; } }

.row {
  display: block; }
  .row::after {
    clear: both;
    content: "";
    display: block; }

/* SMALL COLUMNS */
.col-sm-12 {
  float: left;
  display: block;
  margin-right: 2.85714%;
  width: 100%; }
  .col-sm-12:last-child {
    margin-right: 0; }

.col-sm-6 {
  float: left;
  display: block;
  margin-right: 2.85714%;
  width: 48.57143%; }
  .col-sm-6:last-child {
    margin-right: 0; }

.col-sm-4 {
  float: left;
  display: block;
  margin-right: 2.85714%;
  width: 31.42857%; }
  .col-sm-4:last-child {
    margin-right: 0; }

/* MEDIUM COLUMNS */
@media screen and (min-width: 641px) {
  .col-md-12 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 100%; }
    .col-md-12:last-child {
      margin-right: 0; }
  .col-md-8 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 65.71429%; }
    .col-md-8:last-child {
      margin-right: 0; }
  .col-md-6 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 48.57143%; }
    .col-md-6:last-child {
      margin-right: 0; }
  .col-md-4 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 31.42857%; }
    .col-md-4:last-child {
      margin-right: 0; }
  .col-md-3 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 22.85714%; }
    .col-md-3:last-child {
      margin-right: 0; } }

/* LARGE COLUMNS */
@media screen and (min-width: 1000px) {
  .col-lg-12 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 100%; }
    .col-lg-12:last-child {
      margin-right: 0; }
  .col-lg-8 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 65.71429%; }
    .col-lg-8:last-child {
      margin-right: 0; }
  .col-lg-6 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 48.57143%; }
    .col-lg-6:last-child {
      margin-right: 0; }
  .col-lg-4 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 31.42857%; }
    .col-lg-4:last-child {
      margin-right: 0; }
  .col-lg-3 {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 22.85714%; }
    .col-lg-3:last-child {
      margin-right: 0; } }

/* CENTERED */
.col-sm-10-centered {
  float: left;
  display: block;
  margin-right: 2.85714%;
  width: 82.85714%;
  margin-left: 8.57143%; }
  .col-sm-10-centered:last-child {
    margin-right: 0; }

@media screen and (max-width: 640px) {
  .col-sm-6:nth-child(2n+2),
  .col-sm-4:nth-child(3n+3),
  .col-sm-3:nth-child(4n+4) {
    margin-right: 0; } }

@media screen and (min-width: 641px) {
  .col-md-6:nth-child(2n+2),
  .col-md-4:nth-child(3n+3),
  .col-md-3:nth-child(4n+4) {
    margin-right: 0; } }

@media screen and (min-width: 1000px) {
  .col-lg-6:nth-child(2n+2),
  .col-lg-4:nth-child(3n+3),
  .col-lg-3:nth-child(4n+4) {
    margin-right: 0; } }

/* GENERAL */
* {
  -webkit-touch-callout: none; }

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  font-family: "CorporateS-Regular", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  font-size: 1rem;
  background: white;
  color: #272727; }
  @media screen and (min-width: 641px) {
    html,
    body {
      font-size: 1.125rem; } }

section {
  padding: 30px 0; }
  @media screen and (min-width: 641px) {
    section {
      padding: 60px 0; } }

.orange_bg {
  background: #ff5400;
  color: white; }

::selection {
  background-color: white;
  color: #ff5400; }

/* HELPER CLASSES */
.text-center {
  text-align: center; }

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.spaceTop {
  margin-top: 1.875rem; }
  @media screen and (min-width: 641px) {
    .spaceTop {
      margin-top: 3.75rem; } }

/* IMAGES */
img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  border: none; }

a img {
  border: none; }

/* BUTTONS */
.button {
  background: #ff5400;
  color: white;
  display: inline-block;
  border: none;
  padding: 10px 20px;
  text-align: center;
  margin-top: 30px;
  text-transform: uppercase;
  transition: 0.5s; }

.button:hover {
  transition: 0.5s;
  opacity: 0.5; }

.klaro .cookie-modal .cm-btn.cm-btn-success, .klaro .cookie-notice .cm-btn.cm-btn-success {
  background: #ff5400 !important; }

.klaro .cookie-modal a, .klaro .cookie-notice a {
  border-bottom: none; }

/* TYPO */
strong,
em {
  font-family: "CorporateS-Bold", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }

a {
  color: #272727;
  text-decoration: none;
  border-bottom: 1px solid #272727; }

.orange_bg a {
  color: white;
  border-bottom: 1px solid white; }

.orange_bg .imgLink {
  border-bottom: none; }

.imgLink {
  border-bottom: none; }

h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
  text-align: center;
  text-transform: uppercase; }

@media screen and (min-width: 641px) {
  h2 {
    letter-spacing: 2px; } }

h1 {
  font-family: "CorporateS-Bold", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.25rem;
  margin: 1.25rem 1.25rem 0.625rem; }
  @media screen and (min-width: 641px) {
    h1 {
      font-size: 2.25rem;
      margin: 60px 1.25rem 1.25rem; } }

h2 {
  font-family: "CorporateS-Bold", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #ff5400;
  font-size: 0.875rem; }
  @media screen and (min-width: 641px) {
    h2 {
      font-size: 1.125rem;
      margin: 0 0 30px; } }

h3 {
  position: relative;
  margin: 0 0 60px;
  font-size: 1.5rem; }
  @media screen and (min-width: 641px) {
    h3 {
      font-size: 2.25rem; }
      h3:after {
        width: 150px;
        margin-left: -75px; } }

section h3:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  height: 1px;
  margin-left: -50px;
  width: 100px;
  background: #272727; }
  @media screen and (min-width: 641px) {
    section h3:after {
      font-size: 2.25rem; }
      section h3:after:after {
        width: 150px;
        margin-left: -75px; } }

.orange_bg h3:after {
  background: white; }

.underline {
  font-family: "CorporateS-Regular", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.125rem;
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 0.5625rem;
  color: #272727;
  text-align: left; }
  @media screen and (min-width: 641px) {
    .underline {
      margin-bottom: 1.125rem; } }
  .underline span {
    border-bottom: 1px solid #272727;
    display: inline-block;
    margin: 0 0 -1px 0;
    padding: 0 0.3125rem 0 0; }

h4 {
  font-size: 1rem;
  margin: 0; }
  @media screen and (min-width: 641px) {
    h4 {
      font-size: 1.125rem;
      text-align: left; } }

p,
address {
  margin: 0 0 1rem;
  line-height: 1.5;
  font-style: normal; }

.einleitung {
  text-align: center;
  padding: 0 1.25rem 1.25rem;
  line-height: 1; }
  @media screen and (min-width: 641px) {
    .einleitung {
      font-size: 1.5rem;
      padding: 0 7% 30px; } }
  @media screen and (min-width: 1000px) {
    .einleitung {
      padding: 0 14% 30px; } }

.justify {
  hyphens: auto;
  text-align: justify; }

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block; }

/* HEADER */
header a {
  text-decoration: none;
  display: block;
  color: #272727;
  border-bottom: none; }

header .logo {
  text-align: center;
  position: relative;
  background: #ff5400;
  overflow: hidden;
  width: calc(100% + 30px);
  margin-left: -20px; }
  @media screen and (min-width: 641px) {
    header .logo {
      width: calc(100% + 60px);
      margin-left: -30px; } }
  @media screen and (min-width: 1000px) {
    header .logo {
      overflow: visible;
      width: auto;
      text-align: left;
      float: left;
      margin: 0.9375rem 0 0;
      background: white; }
      header .logo:before {
        background: #ff5400;
        content: ' ';
        position: absolute;
        left: calc(-400% - 30px);
        display: block;
        height: 1.25rem;
        width: 400%;
        overflow: hidden; } }
  header .logo a {
    height: 2.1875rem;
    line-height: 2.1875rem;
    color: white;
    font-family: "CorporateS-Regular", Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    text-transform: uppercase;
    font-size: 1.125rem; }
    @media screen and (min-width: 641px) {
      header .logo a {
        font-size: 1.5rem;
        height: 3.125rem;
        line-height: 3.125rem; } }
    @media screen and (min-width: 1000px) {
      header .logo a {
        height: auto;
        line-height: 1;
        color: #ff5400; } }

/* NAVIGATION */
#magic-line {
  display: none; }
  @media screen and (min-width: 641px) {
    #magic-line {
      display: block;
      position: absolute;
      height: 0.3125rem;
      background: #ff5400;
      width: 100%;
      top: 0;
      left: 0;
      padding: 0 !important;
      margin: 0 !important; } }

#menu-main {
  margin: 0 auto;
  text-align: center;
  position: relative; }
  @media screen and (min-width: 1000px) {
    #menu-main {
      margin: 0 -10px 0 0;
      padding: 0.625rem 0 0;
      text-align: right;
      float: right; } }
  #menu-main li {
    float: left;
    width: 33.334%; }
    @media screen and (min-width: 641px) {
      #menu-main li {
        width: 16.667%; } }
    @media screen and (min-width: 1000px) {
      #menu-main li {
        width: auto; } }
    #menu-main li a {
      text-transform: lowercase;
      text-align: center;
      padding: 0.3125rem 0; }
      @media screen and (max-width: 640px) {
        #menu-main li a {
          font-size: 0.75rem; } }
      @media screen and (min-width: 1000px) {
        #menu-main li a {
          padding: 0.3125rem 0.625rem;
          font-size: 1.125rem; } }

/* PROJEKTLISTE */
section.projekte {
  padding: 0 0 30px;
  text-align: center; }
  @media screen and (min-width: 641px) {
    section.projekte {
      padding: 0 0 60px; } }

#projekte {
  list-style: none;
  margin: 0;
  padding: 30px 0 0; }
  #projekte li {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 48.57143%;
    margin-bottom: 1rem; }
    #projekte li:last-child {
      margin-right: 0; }
    #projekte li:nth-child(2n) {
      margin-right: 0; }
    #projekte li:nth-child(2n+1) {
      clear: left; }
    @media screen and (min-width: 1000px) {
      #projekte li {
        float: left;
        display: block;
        margin-right: 2.85714%;
        width: 31.42857%; }
        #projekte li:nth-child(2n) {
          margin-right: 2.85714%; }
        #projekte li:nth-child(2n+1) {
          clear: none; }
        #projekte li:last-child {
          margin-right: 0; }
        #projekte li:nth-child(3n) {
          margin-right: 0; }
        #projekte li:nth-child(3n+1) {
          clear: left; } }
  #projekte a {
    display: block;
    margin: 0 0 0.3125rem; }
    @media screen and (min-width: 641px) {
      #projekte a {
        margin: 0 0 0.625rem; } }
  #projekte h3 {
    margin: 0;
    text-align: left;
    line-height: 1.5;
    font-family: "CorporateS-Bold", Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 0.75rem;
    text-transform: none; }
    #projekte h3:after {
      display: none; }
    @media screen and (min-width: 641px) {
      #projekte h3 {
        font-size: 1.125rem;
        text-transform: uppercase; } }
  #projekte h4 {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    text-transform: none;
    text-align: left;
    font-size: 0.625rem;
    text-transform: uppercase;
    color: #939393; }

body.home #projekte li {
  display: none; }
  body.home #projekte li video {
    max-width: 100%;
    height: auto; }

button#mehrLaden {
  border: 2px solid #ff5400;
  font-family: "CorporateS-Regular", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  background: white;
  display: inline-block;
  min-width: 80px;
  padding: 12px 20px 10px;
  outline: 0;
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #ff5400;
  cursor: pointer;
  cursor: hand; }
  button#mehrLaden:hover, button#mehrLaden:focus {
    background: #ff5400;
    color: white; }

/* DIE AGENTUR */
@media screen and (min-width: 641px) {
  #agentur .agenturtext {
    float: left;
    display: block;
    margin-right: 2.85714%;
    width: 48.57143%; }
    #agentur .agenturtext:last-child {
      margin-right: 0; } }

.funfacts {
  text-align: center;
  width: 100%;
  height: 400px; }
  @media screen and (min-width: 641px) {
    .funfacts {
      margin-left: 12.5%;
      width: 75%; } }

#funfacts {
  margin: 60px 0 0;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent; }
  @media screen and (min-width: 641px) {
    #funfacts {
      margin: 60px 0; } }

html {
  scroll-behavior: smooth; }

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto; } }

/* KERNKOMPETENZEN */
#kernkompetenzen {
  padding: 30px 0 0; }
  @media screen and (min-width: 1000px) {
    #kernkompetenzen {
      padding: 60px 0; } }
  #kernkompetenzen .col-sm-12 {
    position: relative; }
  #kernkompetenzen h4 {
    letter-spacing: 3px;
    border: 6px white double;
    border-radius: 75px;
    width: 150px;
    height: 150px;
    padding: 50px 1.25rem 0;
    margin: 10px 0 0 -75px;
    position: absolute;
    left: 50%;
    z-index: 2;
    background: #ff5400; }
    @media screen and (min-width: 641px) {
      #kernkompetenzen h4 {
        letter-spacing: 0.3125rem;
        border-radius: 100px;
        width: 200px;
        height: 200px;
        padding: 3.75rem 1.25rem 0;
        margin: 0 0 0 -100px; } }
    #kernkompetenzen h4 span {
      display: block;
      text-align: center; }
  #kernkompetenzen ul {
    letter-spacing: 1px;
    border: 2px solid white;
    padding: 100px 0 50px;
    margin: 100px 30px 30px;
    text-align: center;
    line-height: 1.5; }
    @media screen and (min-width: 641px) {
      #kernkompetenzen ul {
        padding: 150px 0 50px; } }
    @media screen and (min-width: 1000px) {
      #kernkompetenzen ul {
        margin: 100px 0 30px; } }

ul.software li {
  vertical-align: middle;
  padding: 0 1.25rem 30px;
  float: left;
  display: block;
  margin-right: 2.85714%;
  width: 48.57143%; }
  ul.software li:last-child {
    margin-right: 0; }
  ul.software li:nth-child(2n) {
    margin-right: 0; }
  ul.software li:nth-child(2n+1) {
    clear: left; }
  @media screen and (min-width: 1000px) {
    ul.software li {
      float: left;
      display: block;
      margin-right: 2.85714%;
      width: 22.85714%; }
      ul.software li:nth-child(2n) {
        margin-right: 2.85714%; }
      ul.software li:nth-child(2n+1) {
        clear: none; }
      ul.software li:last-child {
        margin-right: 0; } }
  ul.software li a {
    display: block; }

/* BRANCHENERFAHRUNG */
#branchenerfahrung .doughnutWrap {
  display: block; }
  #branchenerfahrung .doughnutWrap::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (min-width: 1000px) {
    #branchenerfahrung .doughnutWrap {
      width: 85%; } }
  #branchenerfahrung .doughnutWrap .doughnut {
    position: relative;
    text-align: right;
    height: 20em; }

#branchenerfahrung ul.kunden {
  width: 100%;
  margin: 30px auto 0;
  text-align: center; }
  @media screen and (min-width: 641px) {
    #branchenerfahrung ul.kunden {
      width: 90%;
      margin: 60px auto 0; } }
  #branchenerfahrung ul.kunden li {
    display: inline-block;
    width: 30%; }
    @media screen and (min-width: 1000px) {
      #branchenerfahrung ul.kunden li {
        width: 19%; } }

/* FOOTER */
footer {
  color: #ff5400;
  position: relative;
  font-size: 0.875rem; }

footer a {
  border-bottom: none; }

/* KONTAKT */
#footer-top {
  max-width: 1400px;
  margin: 0 auto;
  display: block;
  background: white; }
  @media screen and (min-width: 641px) {
    #footer-top {
      height: 135px;
      position: relative; } }

@media screen and (min-width: 641px) {
  .footer-kontakt {
    position: relative;
    height: 135px; } }

.footer-kontakt h3 {
  color: #ff5400;
  font-family: "CorporateS-Bold", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
  margin: 0; }
  @media screen and (min-width: 641px) {
    .footer-kontakt h3 {
      position: absolute;
      text-align: left;
      bottom: 0;
      left: 10px;
      line-height: 0.7; } }

.footer-kontakt address {
  text-align: center;
  line-height: 1.25;
  clear: both;
  margin: 0 0 1.25rem; }
  @media screen and (min-width: 641px) {
    .footer-kontakt address {
      text-align: left;
      line-height: 1.25;
      margin: 0;
      position: absolute;
      left: 120px;
      bottom: 5px;
      width: 300px; } }
  @media screen and (min-width: 1000px) {
    .footer-kontakt address {
      width: 600px; } }
  @media screen and (min-width: 1200px) {
    .footer-kontakt address {
      width: 100%;
      display: block;
      left: 0;
      text-align: center; } }
  .footer-kontakt address span {
    display: block;
    line-height: 1.5; }
    @media screen and (min-width: 641px) {
      .footer-kontakt address span {
        display: inline-block;
        width: 49%; }
        .footer-kontakt address span:nth-child(3) {
          clear: left; } }
    @media screen and (min-width: 1000px) {
      .footer-kontakt address span {
        display: inline;
        width: auto; }
        .footer-kontakt address span:after {
          content: ' . '; }
        .footer-kontakt address span:last-child:after {
          content: ''; } }
  .footer-kontakt address a {
    border-bottom: 1px solid #ff5400;
    color: #ff5400; }
  .footer-kontakt address #tel {
    border-bottom: none;
    pointer-events: none; }
    @media screen and (max-width: 640px) {
      .footer-kontakt address #tel {
        border-bottom: 1px solid #ff5400;
        pointer-events: auto; } }

.footer-anfahrt {
  height: 135px;
  width: 250px;
  margin: 0 auto;
  position: relative;
  /* Wetter Widget */ }
  @media screen and (min-width: 641px) {
    .footer-anfahrt {
      position: absolute;
      width: auto;
      margin: 0;
      bottom: 0;
      right: 0; } }
  .footer-anfahrt #marker {
    position: absolute;
    bottom: 10px;
    left: 50px;
    height: 40px;
    width: 40px;
    text-align: center; }
    .footer-anfahrt #marker h3 {
      background: none;
      height: auto;
      line-height: 1;
      margin: 0;
      color: #ff5400;
      font-size: 0.875rem;
      font-family: "CorporateS-Regular", Helvetica, sans-serif;
      font-weight: normal;
      font-style: normal;
      text-transform: none;
      position: absolute;
      top: -20px;
      left: -15px;
      z-index: 0; }
    .footer-anfahrt #marker a {
      display: block;
      position: relative;
      z-index: 1; }
      .footer-anfahrt #marker a img {
        width: 2.1875rem; }
  .footer-anfahrt img#skyline {
    width: 250px; }
  .footer-anfahrt .wetter {
    margin-left: -200px;
    position: relative; }
    .footer-anfahrt .wetter canvas {
      color: #ff5400;
      position: absolute;
      right: 45px;
      top: 40px; }
    .footer-anfahrt .wetter span.temperature {
      font-size: 0.875rem;
      display: block;
      position: absolute;
      top: 40px;
      right: 10px;
      width: 40px;
      text-align: right; }

/* FOOTER NAV, LOGO, COPY */
#footer-bottom {
  background: #ff5400;
  width: 100%;
  max-width: 100%;
  color: white;
  text-align: center;
  padding: 60px 0;
  margin: -1px 0 0;
  position: relative; }
  #footer-bottom .row {
    max-width: 1400px;
    margin: 0 auto;
    float: none; }
  #footer-bottom p,
  #footer-bottom nav a {
    font-size: 0.875rem; }
  #footer-bottom a {
    color: white; }
  #footer-bottom ul {
    margin-left: 0; }
  #footer-bottom .footer-logo {
    text-align: center;
    font-style: normal;
    margin: 0 0 0.625rem;
    width: 55px;
    height: 55px; }
  #footer-bottom p.copyright {
    margin: 0;
    text-transform: uppercase; }
  #footer-bottom ul.social-list {
    text-align: center; }
    #footer-bottom ul.social-list a {
      border: none;
      font-family: FontAwesome;
      color: white;
      font-size: 1.5rem;
      display: block; }
    #footer-bottom ul.social-list li.social {
      height: 30px;
      width: 30px;
      display: inline-block; }
    @media screen and (min-width: 641px) {
      #footer-bottom ul.social-list {
        position: absolute;
        right: 80px;
        top: 0; } }
  #footer-bottom #footer_tabs li {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    margin: 0 5px; }
  #footer-bottom #footer_tabs li.current {
    border-bottom: 1px solid white; }
  #footer-bottom .tab-content {
    display: none;
    text-align: left;
    padding: 20px 0; }
    #footer-bottom .tab-content dl {
      line-height: 1.5;
      margin: 0 0 1rem; }
      #footer-bottom .tab-content dl dt,
      #footer-bottom .tab-content dl dd {
        display: inline-block;
        margin: 0; }
      #footer-bottom .tab-content dl dt {
        width: 40%; }
        @media screen and (min-width: 641px) {
          #footer-bottom .tab-content dl dt {
            max-width: 200px; } }
      #footer-bottom .tab-content dl dd {
        width: 58%; }
    #footer-bottom .tab-content ol {
      list-style: decimal;
      line-height: 1.5; }
      #footer-bottom .tab-content ol li {
        margin: 0 0 0.625rem;
        display: list-item;
        list-style: decimal; }
  #footer-bottom .tab-content.current {
    display: block; }

a#backtop {
  display: inline-block; }

/* PROJEKT DETAILSEITEN */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick/slick.eot");
  src: url("../fonts/slick/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick/slick.woff") format("woff"), url("../fonts/slick/slick.ttf") format("truetype"), url("../fonts/slick/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: #ff5400;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #ff5400;
      opacity: 0.75; }

.slick-slider {
  margin: 30px 0; }

.slick-dots li.slick-active button::before {
  color: #ff5400;
  opacity: 1; }

.slick-dots li button::before {
  color: #ff5400;
  opacity: 0.5;
  transition: 0.4s; }

.slick-dots li button::before:hover {
  transition: 0.4s; }

.slider-arrows {
  visibility: hidden;
  display: none;
  background: none;
  border: none;
  outline: 0;
  position: absolute; }
  @media screen and (min-width: 641px) {
    .slider-arrows {
      z-index: 10;
      margin-top: -30px;
      display: block;
      visibility: visible;
      top: 50%;
      width: 60px;
      height: 60px;
      opacity: .5;
      transition: 0.4s;
      cursor: pointer; }
      .slider-arrows:hover {
        opacity: 1; } }

.prev {
  left: 10px; }

.next {
  right: 10px; }

ul.details li {
  border-bottom: 1px dotted #cdcdcd;
  padding: inherit;
  line-height: 1.5; }
  ul.details li strong {
    display: inline-block;
    min-width: 6.25rem; }

/* ANIMATIONS */
.animate-spin {
  display: inline-block; }

@keyframes spin {
  0% {
    transform(rotate(0deg)) { } }
  100% {
    transform(rotate(359deg)) { } } }
