/* GRID */

.container {
  @include outer-container(1200px);
  padding: 0 rem(10);
  @include media($tablet) {
    padding: 0 $space-sm; } }

.row {
  @include row(); }



/* SMALL COLUMNS */

.col-sm-12 {
  @include span-columns(12); }

.col-sm-6 {
  @include span-columns(6); }

.col-sm-4 {
  @include span-columns(4); }


/* MEDIUM COLUMNS */

@include media($tablet) {
  .col-md-12 {
    @include span-columns(12); }
  .col-md-8 {
    @include span-columns(8); }
  .col-md-6 {
    @include span-columns(6); }
  .col-md-4 {
    @include span-columns(4); }
  .col-md-3 {
    @include span-columns(3); } }



/* LARGE COLUMNS */

@include media($desktop) {
  .col-lg-12 {
    @include span-columns(12); }
  .col-lg-8 {
    @include span-columns(8); }
  .col-lg-6 {
    @include span-columns(6); }
  .col-lg-4 {
    @include span-columns(4); }
  .col-lg-3 {
    @include span-columns(3); } }

/* CENTERED */

.col-sm-10-centered {
  @include span-columns(10);
  @include shift($n-columns: 1); }

@include media($mobile) {
  .col-sm-6:nth-child(2n+2),
  .col-sm-4:nth-child(3n+3),
  .col-sm-3:nth-child(4n+4) {
    @include omega; } }

@include media($tablet) {
  .col-md-6:nth-child(2n+2),
  .col-md-4:nth-child(3n+3),
  .col-md-3:nth-child(4n+4) {
    @include omega; } }

@include media($desktop) {
  .col-lg-6:nth-child(2n+2),
  .col-lg-4:nth-child(3n+3),
  .col-lg-3:nth-child(4n+4) {
    @include omega; } }
