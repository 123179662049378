* {
  -webkit-touch-callout: none; }

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  @include font-stack();
  line-height: 1;
  font-size: $font-size-sm;
  background: white;
  color: $black;
  @include media($tablet) {
    font-size: $font-size-md; } }

section {
  padding: $space-sm 0;
  @include media($tablet) {
    padding: $space-md 0; } }


.orange_bg {
  background: $orange;
  color: white; }

::selection {
  background-color: white;
  color: $orange; }

/* HELPER CLASSES */

.text-center {
  text-align: center; }

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.spaceTop {
  margin-top: rem(30);
  @include media($tablet) {
    margin-top: rem(60); } }

/* IMAGES */

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  border: none; }

a img {
  border: none; }




/* BUTTONS */

.button {
  background: $orange;
  color: white;
  display: inline-block;
  border: none;
  padding: 10px 20px;
  text-align: center;
  margin-top: $space-sm;
  text-transform: uppercase;
  transition: 0.5s; }
.button:hover {
  transition: 0.5s;
  opacity: 0.5; }

.klaro .cookie-modal .cm-btn.cm-btn-success, .klaro .cookie-notice .cm-btn.cm-btn-success {
  background: #ff5400 !important; }

.klaro .cookie-modal a, .klaro .cookie-notice a {
  border-bottom: none; }
