#agentur {
  .agenturtext {
    @include media($tablet) {
      @include span-columns(6); } } }

.funfacts {
  text-align: center;
  @include media($tablet) {
    margin-left: 12.5%;
    width: 75%; }
  //@include media($desktop)
  ////margin-left: 25%
  ////width: 50%
  //@include span-columns(8)
  //@include shift(2)
  //@include omega
  width: 100%;
  height: 400px; }

#funfacts {
  margin: $space-md 0 0;
  //display: inline-block
  vertical-align: baseline;
  //width: 100%
  //height: 350px
  -webkit-tap-highlight-color: transparent;
  @include media($tablet) {
    margin: $space-md 0; } }

html {
	scroll-behavior: smooth; }

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto; } }
