.animate-spin {
  animation(spin 2.0s infinite linear) {}
  display: inline-block;

  @keyframes spin {
    0% {
      transform(rotate(0deg)) {} }

    100% {
      transform(rotate(359deg)) {} } } }
