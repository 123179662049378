footer {
  color: $orange;
  position: relative;
  font-size: rem(14); }


footer a {
  border-bottom: none; }


/* KONTAKT */

#footer-top {
  max-width: 1400px;
  margin: 0 auto;
  display: block;
  background: white;
  @include media($tablet) {
    height: 135px;
    position: relative; } }

.footer-kontakt {
  @include media($tablet) {
    position: relative;
    height: 135px; }
  h3 {
    color: $orange;
    @include font-stack-bold;
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    @include media($tablet) {
      position: absolute;
      text-align: left;
      bottom: 0;
      left: 10px;
      line-height: 0.7; } }
  address {
    text-align: center;
    line-height: 1.25;
    clear: both;
    margin: 0 0 rem(20);
    @include media($tablet) {
      text-align: left;
      line-height: 1.25;
      margin: 0;
      position: absolute;
      left: 120px;
      bottom: 5px;
      width: 300px; }
    @include media($desktop) {
      width: 600px; }
    @include media($large) {
      width: 100%;
      display: block;
      left: 0;
      text-align: center; }
    span {
      display: block;
      line-height: 1.5;
      @include media($tablet) {
        display: inline-block;
        width: 49%;
        &:nth-child(3) {
          clear: left; } }
      @include media($desktop) {
        display: inline;
        width: auto;
        &:after {
          content: ' . '; }
        &:last-child:after {
          content: ''; } } }
    a {
      border-bottom: 1px solid $orange;
      color: $orange; }
    #tel {
      border-bottom: none;
      pointer-events: none;
      @include media($mobile) {
        border-bottom: 1px solid $orange;
        pointer-events: auto; } } } }

.footer-anfahrt {
  height: 135px;
  width: 250px;
  margin: 0 auto;
  position: relative;
  @include media($tablet) {
    position: absolute;
    width: auto;
    margin: 0;
    bottom: 0;
    right: 0; }
  #marker {
    position: absolute;
    bottom: 10px;
    left: 50px;
    height: 40px;
    width: 40px;
    text-align: center;
    h3 {
      background: none;
      height: auto;
      line-height: 1;
      margin: 0;
      color: $orange;
      font-size: rem(14);
      @include font-stack;
      text-transform: none;
      position: absolute;
      top: -20px;
      left: -15px;
      z-index: 0; }
    a {
      display: block;
      position: relative;
      z-index: 1;
      img {
        width: rem(35); } } }
  img#skyline {
    width: 250px; }
  /* Wetter Widget */
  .wetter {
    margin-left: -200px;
    position: relative;
    canvas {
      color: $orange;
      position: absolute;
      right: 45px;
      top: 40px; }
    span.temperature {
      font-size: rem(14);
      display: block;
      position: absolute;
      top: 40px;
      right: 10px;
      width: 40px;
      text-align: right; } } }


/* FOOTER NAV, LOGO, COPY */

#footer-bottom {
  background: $orange;
  width: 100%;
  max-width: 100%;
  color: white;
  text-align: center;
  padding: 60px 0;
  margin: -1px 0 0;
  position: relative;
  .row {
    max-width: 1400px;
    margin: 0 auto;
    float: none; }
  p,
  nav a {
    font-size: rem(14);
    //margin: 0
 }    //line-height: 1
  a {
    color: white; }
  ul {
    margin-left: 0; }
  .footer-logo {
    text-align: center;
    font-style: normal;
    margin: 0 0 rem(10);
    width: 55px;
    height: 55px; }
  p.copyright {
    margin: 0;
    text-transform: uppercase; }
  ul.social-list {
    text-align: center;
    a {
      border: none;
      font-family: FontAwesome;
      color: white;
      font-size: rem(24);
      display: block; }
    li.social {
      height: 30px;
      width: 30px;
      display: inline-block; }
    @include media($tablet) {
      position: absolute;
      right: 80px;
      top: 0; } }
  #footer_tabs {
    li {
      display: inline-block;
      text-align: center;
      cursor: pointer;
      margin: 0 5px; }
    li.current {
      border-bottom: 1px solid white; } }
  .tab-content {
    display: none;
    text-align: left;
    padding: 20px 0;
    dl {
      line-height: 1.5;
      margin: 0 0 $font-size-sm;
      dt,
      dd {
        display: inline-block;
        margin: 0; }
      dt {
        width: 40%;
        @include media($tablet) {
          max-width: 200px; } }
      dd {
        width: 58%; } }
    ol {
      list-style: decimal;
      line-height: 1.5;
      li {
        margin: 0 0 rem(10);
        display: list-item;
        list-style: decimal; } } }
  .tab-content.current {
    display: block; } }


a#backtop {
  display: inline-block;
  img {
    transition(all 1.0s ease) {} }
  &:hover img {
    transform(rotate(-125deg)) {} } }
