#kernkompetenzen {
  padding: $space-sm 0 0;
  @include media($desktop) {
    padding: $space-md 0; }
  .col-sm-12 {
    position: relative; }
  h4 {
    letter-spacing: 3px;
    border: 6px white double;
    border-radius: 75px;
    width: 150px;
    height: 150px;
    padding: 50px rem(20) 0;
    margin: 10px 0 0 -75px;
    position: absolute;
    left: 50%;
    z-index: 2;
    background: $orange;
    @include media($tablet) {
      letter-spacing: rem(5);
      border-radius: 100px;
      width: 200px;
      height: 200px;
      padding: rem(60) rem(20) 0;
      margin: 0 0 0 -100px; }
    span {
      display: block;
      text-align: center; } }
  ul {

    letter-spacing: 1px;
    border: 2px solid white;
    padding: 100px 0 50px;
    margin: 100px $space-sm $space-sm;
    text-align: center;
    line-height: 1.5;
    @include media($tablet) {
      padding: 150px 0 50px; }
    @include media($desktop) {
      margin: 100px 0 $space-sm; } } }
ul.software {
  li {
    vertical-align: middle;
    padding: 0 rem(20) $space-sm;
    @include span-columns(6);
    @include omega(2n);
    @include media($desktop) {
      @include omega-reset(2n);
      @include span-columns(3); }
    a {
      display: block; } } }
