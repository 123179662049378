header {
  a {
    text-decoration: none;
    display: block;
    color: $black;
    border-bottom: none; }
  .logo {
    text-align: center;
    position: relative;
    background: $orange;
    overflow: hidden;
    width: calc(100% + 30px);
    margin-left: -20px;
    @include media($tablet) {
      width: calc(100% + 60px);
      margin-left: -$space-md/2; }
    @include media($desktop) {
      overflow: visible;
      width: auto;
      text-align: left;
      float: left;
      margin: rem(15) 0 0;
      background: white;
      &:before {
        background: $orange;
        content: ' ';
        position: absolute;
        left: calc(-400% - 30px);
        display: block;
        height: rem(20);
        width: 400%;
        overflow: hidden; } }
    a {
      height: rem(35);
      line-height: rem(35);
      color: white;
      @include font-stack();
      text-transform: uppercase;
      font-size: rem(18);
      @include media($tablet) {
        font-size: rem(24);
        height: rem(50);
        line-height: rem(50); }
      @include media($desktop) {
        height: auto;
        line-height: 1;
        color: $orange; } } } }

/* NAVIGATION */

#magic-line {
  display: none;
  @include media($tablet) {
    display: block;
    position: absolute;
    height: rem(5);
    background: $orange;
    width: 100%;
    top: 0;
    left: 0;
    padding: 0 !important;
    margin: 0 !important; } }

#menu-main {
  margin: 0 auto;
  text-align: center;
  position: relative;
  @include media($desktop) {
    margin: 0 -10px 0 0;
    padding: rem(10) 0 0;
    text-align: right;
    float: right; }
  li {
    float: left;
    width: 33.334%;
    @include media($tablet) {
      width: 16.667%; }
    @include media($desktop) {
      width: auto; }
    a {
      text-transform: lowercase;
      text-align: center;
      padding: rem(5) 0;
      @include media($mobile) {
        font-size: rem(12); }
      @include media($desktop) {
        padding: rem(5) rem(10);
        font-size: rem(18); } } } }
