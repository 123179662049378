@import "bourbon", "neat";

/* Author : Felix Zemmel <info@orangeadvertising.de> */
/* Date : 2015.08.15 */
/* File : css/style.css */
/* Made with Bourbon 4.2.4 + Neat 1.7.2 */
/* http://bourbon.io/ */


/* VARIABLES */
$fg-column: 60px;             // Column Width
$fg-gutter: 30px;             // Gutter Width
$fg-max-columns: 12;          // Total Columns For Main Container

$orange: #ff5400;
$black: #272727;
$lightgrey : #cdcdcd;
$grey: #939393;

$font-stack: "CorporateS-Regular", Helvetica, sans-serif;
$font-stack-bold: "CorporateS-Bold", Helvetica, sans-serif;
$font-size-sm: 1rem;
$font-size-md: 1.125rem;

$mobile: new-breakpoint(max-width 640px);
$tablet: new-breakpoint(min-width 641px);
$desktop: new-breakpoint(min-width 1000px);
$large: new-breakpoint(min-width 1200px);

$space-sm: 30px;
$space-md: 60px;

$visual-grid: true;
$visual-grid-color: #E6F6FF;
$visual-grid-opacity: 0.4;

// $visual-grid: true !default

/* MIXINS */

@mixin font-stack {
  font-family: "CorporateS-Regular", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }

@mixin font-stack-bold {
  font-family: "CorporateS-Bold", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal; }

@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) {
    margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) {
    clear: none; } }


/* GRID */
@import "grid";

/* GENERAL */
@import "general";

/* TYPO */
@import "typo";

/* HEADER */
@import "header";

/* PROJEKTLISTE */
@import "projektliste";

/* DIE AGENTUR */
@import "agentur";

/* KERNKOMPETENZEN */
@import "kernkompetenzen";

/* BRANCHENERFAHRUNG */
@import "branchenerfahrung";

/* FOOTER */
@import "footer";


/* PROJEKT DETAILSEITEN */
@import "slick-theme.sass";

ul.details li {
  border-bottom: 1px dotted $lightgrey;
  padding: inherit;
  line-height: 1.5;
  strong {
    display: inline-block;
    min-width: rem(100); } }


/* ANIMATIONS */
@import "animations";
