$slick-font-path: "../fonts/slick/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "../img/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: $orange !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 20px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";

.slick-slider {
  margin: 30px 0; }

.slick-dots li.slick-active button::before {
  color: $orange;
  opacity: 1; }



.slick-dots li button::before {
  color: $orange;
  opacity: 0.5;
  transition: 0.4s; }

.slick-dots li button::before:hover {
  transition: 0.4s; }

.slider-arrows {
  visibility: hidden;
  display: none;
  background: none;
  border: none;
  outline: 0;
  position: absolute;
  @include media($tablet) {
    z-index: 10;
    margin-top: -30px;
    display: block;
    visibility: visible;
    top: 50%;
    width: 60px;
    height: 60px;
    opacity: .5;
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      opacity: 1; } } }


.prev {
  left: 10px; }

.next {
  right: 10px; }
